<template>
  <div id="advertise-parkingLot-detail">
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item>广告管理</el-breadcrumb-item>
      <el-breadcrumb-item :to="{ name: 'advertise-parkingLot' }"
        >停车场列表</el-breadcrumb-item
      >
      <el-breadcrumb-item>广告列表</el-breadcrumb-item>
    </el-breadcrumb>
    <adList :advtisementType="advtisementType" />
  </div>
</template>
<script>
import adList from "../components/adList.vue";
export default {
  components: {
    adList,
  },
  data() {
    return {
      advtisementType: "停车场",
    };
  },
};
</script>
<style lang="scss">
#advertise-parkingLot-detail {
}
</style>
